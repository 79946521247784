export default function getEngagementLevel(climateObjectivesData, isSbtiTarget) {
  const scope12Rounded = Math.floor(climateObjectivesData?.obj_scope_1_2?.manual_degree_position);
  const scope3Rounded = Math.floor(climateObjectivesData?.obj_scope_3_n_1?.manual_degree_position);

  // Aucun engagement pris ou annoncé
  if (isSbtiTarget >= 5
    && climateObjectivesData?.obj_scope_1_2?.manual_degree_position === null
    && climateObjectivesData?.obj_scope_3_n_1?.manual_degree_position === null
    && (scope12Rounded < 5 || scope3Rounded < 5)) {
    return 1;
  }

  // Engagement en cours de validation
  if (isSbtiTarget === 4) {
    if (climateObjectivesData?.obj_scope_1_2?.manual_degree_position !== null
      && climateObjectivesData?.obj_scope_3_n_1?.manual_degree_position !== null
      && (scope12Rounded < 5 || scope3Rounded < 5)) {
      return 4;
    }

    if (climateObjectivesData?.obj_scope_1_2?.manual_degree_position === null
      && climateObjectivesData?.obj_scope_3_n_1?.manual_degree_position === null) {
      return 3;
    }
  }

  // Engagement aligné avec la science
  if ((scope12Rounded >= 5 && scope3Rounded >= 5)
    || (isSbtiTarget <= 3)) {
    return 5;
  }

  // Engagement non aligné avec la science
  if ((scope12Rounded < 5 || scope3Rounded < 5)
    && (isSbtiTarget === 5 || isSbtiTarget === 7)) {
    return 2;
  }

  // Engagement non éligible
  if (isSbtiTarget === 6) {
    return 6;
  }

  return 1;
}
