import React, { useState, useEffect } from 'react';

import checkIcon from '../../../../assets/ui/circle-check-icon.svg';

function AdvancementCircle({ level, bgColor = '#FFFFFF' }) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {(level === 3)
    && (
    <div className="tw-min-w-6 tw-h-6 tw-flex items-center tw-justify-center">
      <img src={checkIcon} alt="check" className="tw-self-center" />
    </div>
    )}
      {(level === 2)
      && (
      <div className="tw-min-w-6 tw-h-6 tw-flex items-center tw-justify-center">
        <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="none"
            stroke="#051B5E"
            strokeWidth="1.8"
            strokeDasharray="4 3"
            strokeLinecap="round"
          />
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="none"
            stroke="#051B5E"
            strokeWidth="1.8"
            strokeDasharray="30 69.12"
            strokeDashoffset="0"
            strokeLinecap="round"
          />
          <circle
            cx="12"
            cy="12"
            r="11"
            fill="none"
            stroke={bgColor}
            strokeWidth="3"
            strokeDasharray="69.12"
            strokeDashoffset={animate ? '-55' : '0'}
            transform="rotate(-5, 12, 12)"
            style={{
              transition: 'stroke-dashoffset 1s ease-out',
            }}
          />
        </svg>
      </div>
      )}
      {(level === 1)
      && <div className="tw-min-w-5 tw-h-5 tw-rounded-full tw-border-[1.5px] tw-border-greySix tw-flex tw-items-center tw-justify-center tw-border-solid" /> }
    </>

  );
}

export default AdvancementCircle;
