import React from 'react';
import { useOutletContext } from 'react-router-dom';

import './Suppliers.scss';

import Database from '../../Features/Database/Database';

function Suppliers() {
  const { partner, isDashboard, hasWriteAccess } = useOutletContext();

  return (
    <div className="tw-w-full tw-pt-[20px] tw-px-[20px] tw-py-2 2xl:tw-px-12 2xl:tw-py-10 tw-flex tw-flex-col tw-max-w-full tw-max-h-full tw-overflow-hidden tw-font-moskauGrotesk">
      <Database hasWriteAccess={hasWriteAccess} partner={partner} isDashboard={isDashboard} />
    </div>
  );
}

export default Suppliers;
