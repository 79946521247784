import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function SectorInformations({ profile }) {
  const [categories, setCategories] = useState([]);
  const [firstLineCategories, setFirstLineCategories] = useState([]);
  const [secondLineCategories, setSecondLineCategories] = useState([]);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [remainingSpace, setRemainingSpace] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const containerRef = useRef(null);
  const firstCategoryRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (profile?.categories_unfiltered) {
      const sortedCategories = [...profile.categories_unfiltered].sort((a, b) => a.name.localeCompare(b.name));
      setCategories(sortedCategories);
    }
  }, [profile]);

  useEffect(() => {
    if (categories.length === 0) return;

    // Mettre la première catégorie sur la première ligne
    setFirstLineCategories([categories[0]]);

    // setTimeout pour s'assurer que le DOM est mis à jour et que la ref est disponible
    setTimeout(() => {
      if (firstCategoryRef.current && categories.length > 1) {
        const containerWidth = 200;
        const gap = 6;
        const firstCatWidth = firstCategoryRef.current.offsetWidth;
        const space = containerWidth - firstCatWidth - gap;

        if (space >= 40) {
          // On peut ajouter la deuxième catégorie sur la première ligne
          setFirstLineCategories([categories[0], categories[1]]);
          setSecondLineCategories(categories.slice(2, 3));
          setHiddenCategories(categories.slice(3));
        } else {
          // La deuxième catégorie va sur la deuxième ligne
          setSecondLineCategories([categories[1]]);
          setHiddenCategories(categories.slice(2));
        }
        setRemainingSpace(space);
      } else {
        setSecondLineCategories([]);
        setHiddenCategories([]);
      }
    }, 0);
  }, [categories]);

  if (categories.length === 0) {
    return null;
  }

  return (
    <div className="tw-flex tw-flex-col tw-w-[200px]" ref={containerRef}>
      <div className="tw-text-xs tw-text-greyFour tw-mb-1">{t('HEADER_LABEL_SECTOR')}</div>
      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {/* Première ligne */}
        <div className="tw-flex tw-gap-1.5 tw-w-[200px]">
          {firstLineCategories.map((category, index) => (
            <div className="tw-relative" key={category.id}>
              <div
                ref={index === 0 ? firstCategoryRef : null}
                className="tw-bg-white tw-py-1.5 tw-px-2 tw-rounded tw-text-xs tw-text-greyOne tw-truncate tw-whitespace-nowrap tw-border-[1px] tw-border-solid tw-border-greySeven"
                style={{
                  maxWidth: index === 0 ? '200px' : `${remainingSpace}px`,
                  width: 'auto',
                }}
                onMouseEnter={() => setActiveTooltip(category.id)}
                onMouseLeave={() => setActiveTooltip(null)}
              >
                {category.name}
              </div>
              {activeTooltip === category.id && (
                <div className="tw-absolute tw-left-0 tw-top-full tw-mt-2 tw-bg-greyDark tw-text-white tw-p-2 tw-rounded tw-shadow-lg tw-z-50">
                  <div className="tw-absolute tw-left-3 -tw-top-1 tw-w-2 tw-h-2 tw-bg-greyDark tw-rotate-45" />
                  <div className="tw-whitespace-nowrap tw-text-xs">
                    {category.name}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Deuxième ligne */}
        {(secondLineCategories.length > 0 || hiddenCategories.length > 0) && (
          <div className="tw-flex tw-flex-wrap tw-gap-1.5 tw-items-center tw-w-[200px]">
            {secondLineCategories.map((category) => (
              <div className="tw-relative" key={category.id}>
                <div
                  className="tw-bg-white tw-py-1.5 tw-px-2 tw-rounded tw-text-xs tw-text-greyOne tw-truncate tw-border-[1px] tw-border-solid tw-border-greySeven"
                  style={{
                    maxWidth: hiddenCategories.length > 0 ? '154px' : '200px',
                  }}
                  onMouseEnter={() => setActiveTooltip(category.id)}
                  onMouseLeave={() => setActiveTooltip(null)}
                >
                  {category.name}
                </div>
                {activeTooltip === category.id && (
                  <div className="tw-absolute tw-left-0 tw-top-full tw-mt-2 tw-bg-greyDark tw-text-white tw-p-2 tw-rounded tw-shadow-lg tw-z-50">
                    <div className="tw-absolute tw-left-3 -tw-top-1 tw-w-2 tw-h-2 tw-bg-greyDark tw-rotate-45" />
                    <div className="tw-whitespace-nowrap tw-text-xs">
                      {category.name}
                    </div>
                  </div>
                )}
              </div>
            ))}

            {/* hiddenCategories */}
            {hiddenCategories.length > 0 && (
              <div className="tw-relative tw-shrink-0">
                <div
                  className="tw-bg-white tw-py-1.5 tw-px-2 tw-rounded tw-text-xs tw-text-greyOne tw-cursor-pointer tw-border-[1px] tw-border-solid tw-border-greySeven"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                >
                  +{hiddenCategories.length}
                </div>
                {showTooltip && (
                  <div className="tw-absolute tw-left-full tw-ml-2 tw-top-0 tw-bg-greyDark tw-text-white tw-p-2 tw-rounded tw-shadow-lg tw-z-50 tw-min-w-[150px]">
                    <div className="tw-absolute -tw-left-1 tw-top-3 tw-w-2 tw-h-2 tw-bg-greyDark tw-rotate-45" />
                    {hiddenCategories.map((category) => (
                      <div key={category.id} className="tw-whitespace-nowrap tw-text-xs tw-py-0.5">
                        {category.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SectorInformations;
