const getPercentage = (partialValue, totalValue) => Math.round((100 * partialValue) / totalValue);

const carbonFootprintChartBuilder = (totalcarbonFootprintValue, carbonFootprint, carbonFootprintDistribution, isControledPmeWithoutCarbonFootprint, t) => {
  const colorsScope12 = ['#21AFFF', '#21AFFF', '#21AFFF', '#21AFFF'];
  const colorsScope3 = ['#031356', '#112787', '#5675C3'];
  const defaultColorScope3 = '#B3B6DA';
  const defaultNamingData = ['Scope 1 & 2', t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_12')];
  let data = [];
  let dataFiltered = [];

  if ((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || isControledPmeWithoutCarbonFootprint) {
    if (carbonFootprintDistribution.length > 0) {
      carbonFootprintDistribution.sort((a, b) => b.scope - a.scope).sort((a, b) => {
        if (a.scope === 3 && b.scope === 3) {
          return b.percent - a.percent;
        }
        return true;
      });
      let scope3acc = 0;
      let scope12acc = 0;

      data = carbonFootprintDistribution.sort((a, b) => b.percent - a.percent).map((data) => {
        if (data.scope === 12) {
          scope12acc += 1;
        } else {
          scope3acc += 1;
        }

        let color;
        if (data.scope === 12) {
          color = colorsScope12[scope12acc - 1];
        } else {
          color = scope3acc <= 3 ? colorsScope3[scope3acc - 1] : defaultColorScope3;
        }

        return {
          scope: Number(data.scope),
          value: data.percent,
          color,
          id: data.name,
        };
      });
    } else {
      data = carbonFootprint.map((data, index) => ({
        value: getPercentage(data, totalcarbonFootprintValue),
        color: index <= 0 ? colorsScope12[0] : colorsScope3[0],
        id: defaultNamingData[index],
        scope: defaultNamingData[index] === 'Scope 1 & 2' ? 12 : 3,
      }));
    }
  } else if (carbonFootprint && (carbonFootprint[0])) {
    data = [{
      value: 10,
      color: '#21AFFF',
      id: t('DATA_NOT_AVAILABLE'),
      scope: 3,
    }];
  } else if (carbonFootprint && (carbonFootprint[1])) {
    data = [{
      value: 10,
      color: '#000D85',
      id: t('DATA_NOT_AVAILABLE'),
      scope: 3,
    }];
  } else {
    data = [{
      value: 100,
      color: '#F4F4F4',
      id: t('DATA_NOT_AVAILABLE'),
      scope: 3,
    }];
  }

  const totalPercent = data.reduce((acc, item) => acc + Number(item.value), 0);

  if (totalPercent.toFixed(1) < 100) {
    data = [
      ...data,
      {
        color: '#F4F4F4',
        id: t('NOT_COMMUNICATED'),
        scope: 3,
        value: (100 - totalPercent) % 1 !== 0 ? (100 - totalPercent).toFixed(1) : 100 - totalPercent,
      },
    ];
  }

  let scope12total = 0;
  let scopeAcc = 0;
  let scope3other = 0;

  if ((carbonFootprint && carbonFootprint[0] && carbonFootprint[1]) || isControledPmeWithoutCarbonFootprint) {
    data.map((item) => {
      if (item.scope === 12) {
        scopeAcc += parseFloat(item.value);
        scope12total += parseFloat(item.value);
      }
      return true;
    });

    dataFiltered = [
      {
        scope: 12,
        value: Math.ceil(scope12total),
        color: '#21AFFF',
        id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10'),
      },
    ];

    const dataScope3 = data.filter((item) => item.scope === 3);

    dataScope3.map((item, index) => {
      if (dataScope3.length > 4) {
        if (index >= 0 && index < 3 && item.id !== t('NOT_COMMUNICATED')) {
          scopeAcc += parseFloat(item.value);
          dataFiltered.push(
            {
              scope: 3,
              value: Math.ceil(parseFloat(item.value)),
              color: item.color,
              id: item.id,
            },
          );
        }
        if (index >= 3 && item.id !== t('NOT_COMMUNICATED')) {
          scope3other += parseFloat(item.value);
        }
        if (item.id === t('NOT_COMMUNICATED')) {
          scope3other += parseFloat(item.value);
        }
      } else {
        if (index >= 0 && index < 4 && item.id !== t('NOT_COMMUNICATED')) {
          scopeAcc += parseFloat(item.value);
          dataFiltered.push(
            {
              scope: 3,
              value: Math.ceil(parseFloat(item.value)),
              color: item.color,
              id: item.id,
            },
          );
        }
        if (item.id === t('NOT_COMMUNICATED')) {
          scope3other += parseFloat(item.value);
        }
      }
      return true;
    });

    if (scopeAcc < 100) {
      dataFiltered.push(
        {
          scope: 3,
          value: Math.ceil(scope3other),
          color: '#B3B6DA',
          id: t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_11'),
        },
      );
    }
  } else {
    dataFiltered = data;
  }
  return [data, dataFiltered];
};

export default carbonFootprintChartBuilder;
