import React from 'react';

import Status from './Status';

import certified from '../../../../assets/ui/managed.svg';

function CompanyInfo({ company, climateAction, status, t }) {
  return (
    <div className="ms-2">
      <div className="tw-flex tw-items-center tw-font-medium tw-text-greyOne tw-text-sm">
        {company.name}
        <div>
          {company.isManagedSort ? (
            <img
              src={certified}
              alt="Certified"
              className="tw-w-[20px] tw-ml-[4px] -tw-mt-1"
            />
          ) : null}
        </div>
      </div>
      <span className="tw-text-xs tw-font-medium tw-text-greyFour tw-font-moskauGrotesk tw-mt-2">
        {climateAction ? (
          <div className="tw-mt-2">
            {company.isManagedSort
              ? t('COMPETING_COMPANIES_MEMBER')
              : t('COMPETING_COMPANIES_NOT_MEMBER')}
          </div>
        ) : null }
      </span>
      {status && <Status profile={company} />}
    </div>
  );
}

export default CompanyInfo;
