import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';
import { useOutletContext } from 'react-router-dom';

import handleResponse from '../../../utils/handleResponse';
import SuppliersList from './SuppliersList';
import MainChart from './MainChart';
import BarChartLegend from './BarChartLegend';

function SbtiDistribution({ selectedCategoryId, getCategoryName, isOpen, selectedStatus, setSelectedStatus }) {
  const [sbtiPercentageByStatus, setSbtiPercentageByStatus] = useState();
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const [profileCountsBySbtiStatus, setProfileCountsBySbtiStatus] = useState();
  const [colorsObjects, setColorsObjects] = useState();
  const [legend, setLegend] = useState();
  const [profiles, setProfiles] = useState();

  const { partner } = useOutletContext();

  const { t } = useTranslation();

  const baseUrl = `${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/sbti-stats`;
  const categoryParam = selectedCategoryId
    ? `?categoryId=${selectedCategoryId}`
    : '';
  const url = `${baseUrl}${categoryParam}`;

  const { get, response } = useFetch(url, {
    credentials: 'include',
    cachePolicy: 'no-cache',
  });

  const getStats = async () => {
    try {
      const result = await get();
      if (response.ok) {
        setSbtiPercentageByStatus(result?.percentage_by_sbti_status);
        setTotalProfileCount(result?.profile_count);
        setProfileCountsBySbtiStatus(result?.profile_count_by_sbti_status);
        setColorsObjects(result?.colors);
        setLegend(result?.colors);
        setProfiles(result?.profiles);
      }
    } catch (error) {
      handleResponse(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    getStats();
  }, [selectedCategoryId]);

  const filteredProfiles = selectedStatus
    ? profiles?.filter((profile) => profile.sbti_status === selectedStatus)
    : profiles;

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const supplierCount = selectedStatus ? profileCountsBySbtiStatus[selectedStatus] : totalProfileCount;
  const supplierText = `${supplierCount} ${t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.SUPPLIERS')}${supplierCount > 1 ? 's' : ''}`;

  return (
    <div className="tw-max-w-[675px] tw-flex tw-flex-col tw-bg-white tw-border tw-border-solid tw-border-greySix tw-rounded-md tw-py-[15px] tw-pl-5 max-[903px]:tw-pr-5 tw-pr-11">
      <div className="tw-text-base tw-font-inter tw-font-bold tw-text-greyOne">{t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.TITLE')}<span className="tw-text-sm tw-font-normal tw-text-greyFour tw-ml-5">{selectedCategoryId ? getCategoryName(selectedCategoryId) : t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.CATEGORY')}</span></div>
      <div className="tw-w-full tw-flex tw-items-center tw-gap-x-5 tw-mt-1">
        <div className="tw-w-[159px] tw-text-base tw-text-greyThree">
          {supplierText}
        </div>
      </div>

      <div className="tw-w-full tw-flex max-[903px]:tw-flex-col max-[903px]:tw-items-center tw-items-stretch tw-gap-x-5 max-[903px]:tw-gap-y-5 tw-mt-[14px]">
        <div className="tw-w-[159px] tw-h-[272px]">
          <MainChart
            sbtiPercentageByStatus={sbtiPercentageByStatus}
            profileCountsBySbtiStatus={profileCountsBySbtiStatus}
            colorsObjects={colorsObjects}
            onStatusSelect={handleStatusSelect}
            selectedCategoryId={selectedCategoryId}
            selectedStatus={selectedStatus}
            isOpen={isOpen}
          />
        </div>
        <SuppliersList profilesList={filteredProfiles} />
      </div>
      <BarChartLegend legend={legend} selectedStatus={selectedStatus} onStatusSelect={handleStatusSelect} />
    </div>
  );
}

export default SbtiDistribution;
