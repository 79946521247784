import React from 'react';
import { useTranslation } from 'react-i18next';

import questionMarkCircled from '../../../../assets/ui/question-mark-circled.svg';

import getClimateAlignementInfos from '../../../../utils/getClimateAlignementInfos';
import getHexColorWithOpacity from '../../../../utils/getHexColorWithOpacity';

export default function Flag({ data, flagColor, unitText }) {
  const { t } = useTranslation();

  return (
    <>
      {/* Desktop FLAG */}
      <div className="tw-hidden tw-w-full lg:tw-flex tw-items-center tw-py-3 tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix">
        <div className="tw-w-2/5 tw-flex tw-items-center tw-gap-x-2">
          <div key={flagColor} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-start tw-mt-[1px] tw-bg-${data?.percent_reduction_target ? getClimateAlignementInfos(data?.manual_degree_position).bgColor : 'notePasDengagement'} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(flagColor, 0.1)}`, backgroundClip: 'padding-box' }} />
          <div className="tw-text-sm tw-text-greyOne tw-font-moskauGrotesk tw-font-semibold tw-inline-block tw-relative">
            <div>
              <span className="tw-mr-1">
                {t('CLIMATE_ACTIONS.FLAG')}
              </span>
              <div className="tw-inline-block tw-align-middle tw-group">
                <img src={questionMarkCircled} alt="Question Mark" />
                <div className={`tw-absolute tw-top-full tw-invisible group-hover:tw-visible tw-max-w-44 tw-min-w-44 tw-z-10 tw-py-2 tw-px-3 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyTwo tw-rounded-sm tw-shadow-lg tw-ml-6 ${data?.perimeter ? '-tw-my-[49px]' : '-tw-my-[27px]'}`}>
                  <div className="tw-absolute -tw-left-[4px] tw-top-[10px] tw-w-3 tw-h-3 tw-bg-greyTwo tw-rotate-45 tw-rounded-sm" />
                  {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                </div>
              </div>
            </div>
            {data?.perimeter && (
              <div className="tw-text-[10px] tw-text-greyFour tw-font-normal tw-inline-block tw-relative">
                {data?.perimeter}
              </div>
            )}
          </div>
        </div>
        <div className="tw-w-3/5 tw-flex tw-items-center">
          <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
            {data?.percent_reduction_target ? (
              <>
                <div className="tw-font-moskauGrotesk tw-font-semibold tw-text-xl">
                  {
                    data?.percent_reduction_target
                  }
                  %
                </div>
                <div className="tw-text-xs tw-text-greyTwo">
                  {t(unitText[data?.direct_value_type])}
                </div>
                <div className="tw-text-xs tw-text-greyTwo">
                  {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                  {
                    data?.flag_initial_year
                  }{' '}
                  et{' '}
                  {data?.flag_final_year}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="tw-w-1/2 tw-flex tw-flex-col tw-items-center">
            {data?.percent_reduction_target && data?.manual_degree_position >= 4 && data?.percent_reduction_target !== 1.5 ? (
              <div
                className={`tw-w-24 tw-text-sm tw-font-medium tw-text-${
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).textColor
                } tw-text-center tw-py-1 tw-bg-${
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).bgColor
                }/10 tw-rounded-2xl tw-border-${
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).textColor
                } tw-border tw-border-solid`}
              >
                <span className="tw-relative tw-top-[1px]">
                  {
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).text
                }
                </span>
              </div>
            ) : (
              <>
                <div className="tw-text-xs tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
                  {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                </div>
                { data?.percent_reduction_target ? (
                  <div className="tw-text-xs tw-text-noteEncoreInsufissante tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                    {t('CLIMATE_ACTIONS.INSUFFICIENT_COMMITMENT')}
                  </div>
                ) : (
                  <div className="tw-text-xs tw-text-notePasDengagement tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                    {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Mobile FLAG */}
      <div className="tw-w-full tw-flex lg:tw-hidden tw-flex-col tw-gap-y-6 tw-border tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-greySix tw-py-6">
        <div className="tw-w-full tw-flex tw-items-center tw-gap-x-2">
          <div key={flagColor} className={`tw-w-4 tw-h-4 tw-min-h-4 tw-min-w-4 tw-self-start tw-mt-[1px] tw-bg-${data?.percent_reduction_target ? getClimateAlignementInfos(data?.manual_degree_position).bgColor : 'notePasDengagement'} tw-rounded-full`} style={{ border: `4px solid ${getHexColorWithOpacity(flagColor, 0.1)}`, backgroundClip: 'padding-box' }} />
          <div className="tw-text-sm tw-text-greyOne tw-font-moskauGrotesk tw-font-semibold tw-relative">
            <div>
              <span className="tw-mr-1">
                {t('CLIMATE_ACTIONS.FLAG')}
              </span>
              <div className="tw-inline-block tw-align-middle tw-group">
                <img src={questionMarkCircled} alt="Question Mark" />
                <div className="tw-absolute tw-top-[100%] sm:tw-top-full -tw-right-8 sm:-tw-right-[130%] tw-invisible group-hover:tw-visible md:tw-top-full tw-min-w-44 tw-max-w-44 tw-z-10 tw-p-2 tw-text-xs tw-text-white tw-font-normal tw-font-moskauGrotesk tw-bg-greyTwo tw-rounded-sm tw-shadow-lg -tw-mb-[48px] sm:-tw-my-[48px] sm:tw-ml-10">
                  {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                </div>
              </div>
            </div>
            {data?.perimeter && (
              <div className="tw-text-[10px] tw-text-greyFour tw-font-normal tw-inline-block tw-relative">
                {data?.perimeter}
              </div>
            )}
          </div>
        </div>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-w-1/2 tw-text-xs tw-text-greyFour tw-leading-[18px]">
            {t('CLIMATE_ACTIONS.TARGET_REDUCTION')}
          </div>
          <div className="tw-flex tw-flex-col tw-items-center">
            {data?.percent_reduction_target ? (
              <>
                <div className="tw-font-moskauGrotesk tw-font-semibold tw-text-xl">
                  {
                    data?.percent_reduction_target
                  }
                  %
                </div>
                <div className="tw-text-xs tw-text-greyTwo">
                  {t(unitText[data?.direct_value_type])}
                </div>
                <div className="tw-text-xs tw-text-greyTwo">
                  {t('CLIMATE_ACTIONS.BETWEEN')}{' '}
                  {
                    data?.flag_initial_year
                  }{' '}
                  et{' '}
                  {data?.flag_final_year}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <div className="tw-w-1/2 tw-text-xs tw-text-greyFour tw-leading-[18px]">
            {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
          </div>
          <div className="tw-flex tw-flex-col tw-items-center">
            {data?.percent_reduction_target && data?.manual_degree_position >= 4 && data?.percent_reduction_target !== 1.5 ? (
              <div
                className={`tw-w-24 tw-text-sm tw-font-medium tw-text-${
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).textColor
                } tw-text-center tw-py-1 tw-bg-${
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).bgColor
                }/10 tw-rounded-2xl tw-border-${
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).textColor
                } tw-border tw-border-solid`}
              >
                <span className="tw-relative tw-top-[1px]">
                  {
                  getClimateAlignementInfos(
                    data?.manual_degree_position,
                  ).text
                }
                </span>
              </div>
            ) : (
              <>
                <div className="tw-text-xs tw-text-greyThree tw-font-moskauGrotesk tw-font-normal">
                  {t('CLIMATE_ACTIONS.NO_ALIGNEMENT')}
                </div>
                { data?.percent_reduction_target ? (
                  <div className="tw-text-xs tw-text-noteEncoreInsufissante tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                    {t('CLIMATE_ACTIONS.INSUFFICIENT_COMMITMENT')}
                  </div>
                ) : (
                  <div className="tw-text-xs tw-text-notePasDengagement tw-font-moskauGrotesk tw-font-semibold tw-mt-1">
                    {t('CLIMATE_ACTIONS.NO_COMMITMENT_2')}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
