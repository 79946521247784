const getCarbonFootprintDistribution = async (id, locale, profileType, reportingId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${id}/carbon-footprint-distribution?locale=${locale}&profile_type=${profileType}&reporting_id=${reportingId}`);

    if (response.status === 200) {
      const result = await response.json();

      return result;
    }

    return false;
  } catch (error) {
    throw error;
  }
};

export default getCarbonFootprintDistribution;
