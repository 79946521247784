/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTranslation } from 'react-i18next';

import blueCheckmark from '../../../../../assets/ui/blue-checkmark.svg';
import emptyCheckmark from '../../../../../assets/ui/empty-checkmark.svg';
import informationIcon from '../../../../../assets/ui/i-rounded-full.svg';

function TableOfContents({ hasWriteAccess, numberOfQuestions, numberOfAnsweredQuestions, progressionBar, partner, summary, isPublished }) {
  const { t } = useTranslation();

  return (
    <div className="sticky-top" style={{ top: '85px' }}>
      <div className="summary tw-rounded tw-border tw-border-solid tw-border-greySix bg-white black-color">
        <h3 className="summary-title tw-text-base">
          {t('PRIVATE_ANSWERS.TABLE_OF_CONTENTS.TITLE')}
        </h3>
        <div className="summary-second-section mt-3">
          {hasWriteAccess && (
          <div className="d-flex justify-content-between pb-3">
            <div className="summary-progression-bar-container position-relative align-self-center">
              <div className="summary-progression-bar position-absolute" />
              <div className="summary-progression-bar-fill position-absolute" style={{ width: `${progressionBar && progressionBar}%` }} />
            </div>
            <div className="fw-400 font-12">
              {numberOfAnsweredQuestions} / {numberOfQuestions} {t('TABLE_OF_CONTENTS_TEXT_1')}
            </div>
          </div>
          )}
          {summary && summary.map((question, index) => (
            hasWriteAccess ? (
              <a href={`#${question.question}`} key={index} className={`d-flex justify-content-between text-decoration-none ${question.answer ? 'summary-question' : 'summary-question-empty'}`}>
                <div className="p-2 font-14 fw-400 d-block">
                  {question.question}
                </div>
                {question.answer ? (
                  <div className="tw-flex tw-content-center">
                    <img className="p-2 tw-flex tw-self-center" src={blueCheckmark} alt="blue checkmark" />
                  </div>
                ) : (
                  <div className="tw-flex tw-content-center">
                    <img className="p-2 tw-flex tw-self-center" src={emptyCheckmark} alt="empty checkmark" />
                  </div>
                )}
              </a>
            ) : (
              question.isRequired ? (
                <a href={`#${question.question}`} key={index} className={`p-2 font-14 fw-400 ${!question.answer || !isPublished ? 'summary-question-empty' : 'summary-question'} text-decoration-none d-block`}>
                  {question.question}
                </a>
              ) : (
                (question.answer || hasWriteAccess)
                    && (
                    <a href={`#${question.question}`} key={index} className={`p-2 font-14 fw-400 ${!question.answer || !isPublished ? 'summary-question-empty' : 'summary-question'} text-decoration-none d-block`}>
                      {question.question}
                    </a>
                    )
              )
            )
          ))}
        </div>
      </div>
      {partner ? (
        <div className="tw-flex tw-flex-col tw-gap-y-4 tw-rounded tw-border tw-border-solid tw-border-greySix tw-mt-6 tw-p-6 tw-font-moskauGrotesk tw-text-greyOne tw-mb-6">
          <div className="tw-flex tw-items-center tw-gap-x-3 tw-mb-[2px]">
            <img src={informationIcon} width={20} height={20} alt="informations" />
            <span className="tw-font-semibold tw-text-greyOne tw-mt-1">{t('PRIVATE_ANSWERS.DISCLAIMER.TITLE')}</span>
          </div>
          <span className="tw-text-sm tw-font-normal">{t('PRIVATE_ANSWERS.DISCLAIMER.TEXT', { name: partner?.name })}</span>
        </div>
      ) : (
        ''
      )}

    </div>
  );
}

export default TableOfContents;
