export default function getCarbonFootprintLevel(carbonFootprint, isPlannedPublicationDateValid = false) {
  const hasScope12 = carbonFootprint?.[0] !== null;
  const hasScope3 = carbonFootprint?.[1] !== null;

  if (hasScope12 && hasScope3) {
    return 3;
  } if (hasScope12 || hasScope3 || isPlannedPublicationDateValid) {
    return 2;
  }
  return 1;
}
