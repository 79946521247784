import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function PartnerProfileCarbonWeightAndTurnover({ profile, partner, featuresCarbonWeight, featuresTurnover }) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [titleCard, setTitleCard] = useState('');
  const contentRef = useRef(null);

  useEffect(() => {
    if (featuresCarbonWeight && featuresTurnover) {
      setTitleCard(t('P_CWT.TITLE_FULL'));
    } else if (featuresCarbonWeight && !featuresTurnover) {
      setTitleCard(t('P_CWT.TITLE_WEIGHT'));
    } else if (featuresTurnover && !featuresCarbonWeight) {
      setTitleCard(t('P_CWT.TITLE_TURNOVER'));
    }
  }, [featuresCarbonWeight, featuresTurnover]);

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  return (
    <div className="tw-p-5 white-bg mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded tw-relative">
      <h1 className="tw-text-greyOne tw-text-base tw-mb-3.5">
        {titleCard}
      </h1>
      <div className="tw-flex tw-flex-col">
        {featuresCarbonWeight && (
          <div className={`tw-flex tw-flex-col tw-mb-3 ${featuresTurnover ? 'tw-border-b tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-greyMediumLight tw-border-solid' : ''}`}>
            <div className={`tw-flex tw-w-full tw-justify-between tw-items-center tw-text-sm tw-text-greyOne tw-font-medium tw-mt-[5px] ${featuresTurnover ? ' tw-mb-[14px]' : ''} `}>
              <span className="tw-text-greyOne tw-text-sm">{t('P_CWT.CARBON_WEIGHT_WITH', { partner: partner?.name })}</span>
              <span className="tw-font-semibold">
                {profile.carbon_weight_total ? (
                  `${(profile.carbon_weight_total / 1000).toFixed(2)}kt`
                ) : (
                  t('P_CWT.UNKNOWN')
                )}
              </span>
            </div>
          </div>
        )}
        {featuresTurnover && (
          <div className="tw-flex tw-flex-col">
            <div className="tw-flex tw-w-full tw-justify-between tw-items-center tw-text-sm tw-text-greyOne tw-font-medium tw-mt-[5px]">
              <span className="tw-text-greyOne tw-text-sm">{t('P_CWT.TURNOVER_WITH', { partner: partner?.name })}</span>
              <span className="tw-font-semibold">
                {profile.turnover_amount_total ? (
                  `${(profile.turnover_amount_total / 1000000).toFixed(2)}M€`
                ) : (
                  t('P_CWT.UNKNOWN')
                )}
              </span>
            </div>
          </div>
        )}
        {profile?.categories && profile?.categories.length > 0 && (
          <button type="button" className="d-flex border-0 bg-white tw-text-sm tw-my-[14px] ps-0 tw-text-greyFour tw-underline" onClick={toggleShowDetail}>
            {showDetail ? t('P_CWT.HIDE_DETAILS', { name: profile?.partner_category_level0 }) : t('P_CWT.SHOW_DETAILS', { name: profile?.partner_category_level0 })}
          </button>
        )}
        <div ref={contentRef} style={{ maxHeight: (showDetail) ? contentRef.current.scrollHeight : '0px', overflow: 'hidden' }} className="tw-transition-all tw-duration-300 tw-overflow-hidden">
          <div className="tw-grid tw-grid-cols-4 tw-bg-greyEight tw-text-greyFour tw-border-b tw-border-greySix tw-border-solid tw-border-b-1 tw-border-t-0 tw-border-l-0 tw-border-r-0 tw-text-xs tw-w-full">
            <span className={`tw-py-2 ${featuresCarbonWeight && featuresTurnover ? 'tw-col-span-2' : 'tw-col-span-3'} tw-col-span-2   tw-pl-[10px]`}>{profile?.partner_category_level0}</span>
            {featuresCarbonWeight && (<span className="tw-py-2 tw-col-span-1">CO2e</span>)}
            {featuresTurnover && (<span className="tw-py-2 tw-col-span-1 tw-pr-[10px] tw-text-right">CA</span>)}
          </div>
          {profile?.categories_unfiltered && profile?.categories_unfiltered.map((item) => (
            <div key={item.id} className="tw-grid tw-grid-cols-4 tw-py-[5px] tw-items-center">
              <div className={`${featuresCarbonWeight && featuresTurnover ? 'tw-col-span-2' : 'tw-col-span-3'} tw-group tw-pr-2 tw-pl-[10px] tw-text-left tw-text-greyOne`}>
                {item.name.length > 20 && showDetail ? (
                  <div className="tw-w-auto tw-absolute tw-left-14 tw-p-2 tw-mb-1 tw-text-xs tw-text-white tw-font-moskauGrotesk tw-font-normal tw-bg-greyTwo tw-rounded-sm tw-shadow-lg group-hover:tw-opacity-100 tw-opacity-0 tw-transition-opacity tw-duration-100 tw-z-20">
                    {item.name}
                  </div>
                ) : null}
                <div className="tw-text-xs tw-text-ellipsis tw-overflow-hidden tw-line-clamp-2 tw-pr-2">
                  {item.name}
                </div>
              </div>
              {featuresCarbonWeight && (
                <span className="tw-col-span-1 tw-text-left tw-font-medium tw-text-greyOne tw-text-xs tw-z-10">
                  {item.carbon_weight ? (
                    `${(item.carbon_weight / 1000).toFixed(2)}kt`
                  ) : (
                    '-'
                  )}
                </span>
              )}
              {featuresTurnover && (
                <span className="tw-col-span-1 tw-pr-[10px] tw-font-medium tw-text-greyOne tw-text-xs tw-z-10 tw-text-right">
                  {item.turnover_amount ? (
                    `${(item.turnover_amount / 1000000).toFixed(2)}M€`
                  ) : (
                    '-'
                  )}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PartnerProfileCarbonWeightAndTurnover;
