import React from 'react';
import { useTranslation } from 'react-i18next';

function SuppliersList({ profilesList }) {
  const { t } = useTranslation();

  const profilesSortedList = profilesList ? profilesList.sort((a, b) => a.name.localeCompare(b.name)) : [];

  return (
    <div className="tw-flex-1 tw-bg-greyEight tw-py-3 tw-px-5 tw-flex tw-flex-col tw-max-h-[272px]">
      <div className="tw-text-xs tw-text-greyFour tw-font-moskauGrotesk tw-font-medium">
        {t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.SUPPLIERS_LIST')}
      </div>
      <div id="suppliers-list" className="tw-flex tw-flex-col tw-gap-y-2 tw-overflow-y-auto tw-mt-2 tw-h-[calc(100%-35px)]">
        {profilesSortedList && profilesSortedList.map((item) => (
          <div key={item.id} className="tw-text-xs tw-text-primaryNewBlue">{item?.name}</div>
        ))}
      </div>
    </div>
  );
}

export default SuppliersList;
