import { useState, useEffect } from 'react';
import { useFetch } from 'use-http';

/**
 * Custom hook to fetch and manage partner profile level zero categories.
 *
 * @param {string} profileId - The profile ID.
 * @param {string} partnerId - The partner ID.
 * @param {Object} [initialCategory=null] - The initial category to be selected.
 * @returns {Object} - An object containing the categories array, selectedCategory, and setSelectedCategory function.
 */
const usePartnerProfileLevelZeroCategories = (profileId, partnerId, initialCategory = null) => {
  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await get(`/partner/${partnerId}/profiles/${profileId}/level-zero-categories`);
        if (response.ok && data?.length > 0) {
          const sortedCategories = [...data].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
          setCategories(sortedCategories);
          // Select the initial category if it exists
          if (initialCategory && sortedCategories.some((cat) => cat.id === initialCategory.id)) {
            setSelectedCategory(initialCategory);
          } else {
            setSelectedCategory(sortedCategories[0]);
          }
        }
      } catch (error) {
        setCategories([]);
        setSelectedCategory(null);
        throw error;
      }
    };

    fetchCategories();
  }, [profileId, partnerId]);

  return { categories, selectedCategory, setSelectedCategory };
};

export default usePartnerProfileLevelZeroCategories;
