import React, { useState, useRef, useEffect } from 'react';

import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';

function CategoryFilter({
  categories,
  filteredCategory,
  onCategoryChange,
  isFilterDisabled,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    if (!isFilterDisabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleCategorySelect = (category) => {
    onCategoryChange(category);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="tw-relative tw-rounded-sm tw-w-full tw-mb-2">
      {/* Dropdown Button */}
      <button
        type="button"
        disabled={isFilterDisabled}
        className={`tw-relative tw-max-h-10 tw-h-10 tw-border tw-border-solid tw-border-greySix tw-text-greyFour tw-font-moskauGrotesk tw-text-sm tw-px-4 tw-w-full tw-flex tw-justify-between tw-items-center ${
          isOpen ? 'tw-rounded-t-[4px] tw-ring-2 tw-ring-secondarySkyBlue tw-ring-opacity-50' : 'tw-rounded-[4px]'
        } tw-bg-white`}
        onClick={handleToggle}
      >
        <div className="tw-transition-all tw-duration-600 tw-flex tw-flex-col tw-items-start">
          <div className="tw-transition-all tw-duration-200 tw-text-greyOne tw-line-clamp-1 tw-text-left">
            {filteredCategory ? filteredCategory.name : ''}
          </div>
        </div>
        {!isFilterDisabled && (
          <img
            src={dropdownArrow}
            className={`${isOpen ? 'tw-rotate-180' : ''} tw-duration-500 tw-transition-all`}
            alt="Dropdown arrow"
          />
        )}
      </button>

      {/* Dropdown Content - Only render if not disabled */}
      {!isFilterDisabled && (
        <div
          className={`tw-absolute tw-top-[42px] tw--ml-[2px] tw--mr-[2px] tw-w-[calc(100%+4px)] tw-z-50 tw-transition-all tw-duration-200 tw-overflow-hidden tw-bg-white tw-border tw-border-t-0 tw-border-solid tw-border-greySix tw-rounded-b-[4px] tw-shadow-md ${
            isOpen ? 'tw-visible max-h-[400px]' : 'tw-invisible max-h-0'
          } tw-pb-[5px] tw-font-moskauGrotesk tw-font-medium`}
        >
          <div className="tw-flex tw-flex-col tw-text-xs">
            {categories.map((category, index) => (
              <button
                key={category.id}
                type="button"
                onClick={() => handleCategorySelect(category)}
                className={`tw-px-4 tw-py-3 tw-w-full tw-flex tw-justify-between tw-items-center tw-text-xs tw-text-left tw-border-t-0 tw-border-x-0 tw-border-solid tw-border-b-greySeven tw-text-greyTwo tw-bg-white hover:tw-bg-greyEight ${
                  index === categories.length - 1 ? 'tw-border-b-0' : 'tw-border-b'
                }`}
              >
                <span className="tw-text-sm tw-text-primaryNewBlue tw-font-medium tw-line-clamp-1">
                  {category.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default CategoryFilter;
