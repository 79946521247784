import React, { useState, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import getLocalLink from '../../../utils/getLocalLink';
import getUserLocal from '../../../utils/getUserLocal';

import magnifyingGlass from '../../../assets/ui/magnifying_glass_grey.svg';
import deleteCross from '../../../assets/ui/cross-primaryNewblue.svg';

function ProfileSearch({ partner }) {
  const [items, setItems] = useState([]);
  const [query, setQuery] = useState('');
  const [searchDone, setSearchDone] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const { t } = useTranslation();
  const locale = getUserLocal();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/profiles/search`, {
    credentials: 'include',
  });

  const resetSearch = () => {
    setQuery('');
    setSearchDone(false);
    setIsSearchActive(false);
    setItems([]);
  };

  const handleClearSearch = () => {
    resetSearch();
  };

  const handleResultClick = () => {
    resetSearch();
  };

  const handleOverlayInteraction = (event) => {
    if (event.type === 'click' || event.key === 'Escape') {
      handleClearSearch();
    }
  };

  useEffect(() => {
    const delay = 500;
    let timeout;

    if (query.length >= 1) {
      timeout = setTimeout(async () => {
        try {
          const data = await get(`?query=${query}&domain=${partner.domain}`);
          if (response.ok) {
            setItems(data);
            setSearchDone(true);
            setIsSearchActive(true);
          }
        } catch (error) {
          throw error;
        }
      }, delay);
    } else {
      setItems([]);
      setSearchDone(false);
      setIsSearchActive(false);
    }

    return () => clearTimeout(timeout);
  }, [query, get, response.ok]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (!e.target.value) {
      setIsSearchActive(false);
    }
  };

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="tw-font-medium tw-text-primaryNewBlue">
            {part}
          </span>
        ) : (
          <span key={index}>{part}</span>
        )))}
      </span>
    );
  };

  return (
    <>
      {isSearchActive && (
      <div
        className="tw-fixed tw-inset-0 tw-bg-[#2121211A] tw-backdrop-blur-[7px] tw-z-40 tw-transition-all tw-duration-300 tw-ease-in-out"
        onClick={handleOverlayInteraction}
        onKeyDown={handleOverlayInteraction}
        role="presentation"
      />
      )}
      <div className="tw-relative tw-w-[360px] tw-z-50 tw-h-[35px] tw-font-moskauGrotesk">
        <div className={`tw-flex tw-flex-col tw-w-full tw-transition-all tw-duration-300 tw-ease-in-out ${
          isSearchActive ? 'tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bg-white tw-rounded-[6px] tw-shadow-lg tw-border-none' : ''
        }`}
        >
          <div className="tw-relative tw-flex tw-items-center tw-h-[35px] tw-px-3">
            <div className="tw-absolute tw-left-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-pointer-events-none">
              <img
                src={magnifyingGlass}
                alt="Search icon"
                className="tw-w-4 tw-h-4 tw-ml-2"
              />
            </div>
            <input
              type="text"
              className={`tw-w-full tw-h-full tw-pl-8 tw-py-2.5 tw-border-0 tw-transition-all tw-duration-300 tw-ease-in-out ${
                isSearchActive ? 'tw-bg-white tw-rounded-t-[6px]' : 'tw-bg-greyEight tw-rounded-[20px]'
              } tw-font-normal tw-text-primaryNewBlue tw-text-base placeholder:tw-text-sm tw-mt-[2px] focus:tw-outline-0 focus:tw-outline-none focus:tw-ring-0 tw-appearance-none`}
              placeholder={t('PROFILE_FILTER')}
              value={query}
              onChange={handleInputChange}
            />
            {query.length > 0 && (
            <button
              type="button"
              className="tw-absolute tw-right-3 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-border-none tw-bg-transparent tw-transition-opacity tw-duration-300 tw-ease-in-out"
              onClick={handleClearSearch}
            >
              <img src={deleteCross} alt="Delete icon" />
            </button>
            )}
          </div>

          {isSearchActive && searchDone && (
          <div className="tw-animate-fadeIn">
            <div className="tw-w-full tw-px-[14px] tw-py-2.5 tw-text-greyFour tw-text-xs tw-font-medium">
              {locale === 'fr' && (
                `${t('PROFILE_FILTER_RESULTS_A')}${items.length < 2 ? '' : 's'} ${t('PROFILE_FILTER_RESULTS_B')} (${items.length})`
              )}
              {locale === 'en' && (
                `${t('PROFILE_FILTER_RESULTS_A')} ${t('PROFILE_FILTER_RESULTS_B')}${items.length < 2 ? '' : 's'} (${items.length})`
              )}
            </div>
            <div className="tw-rounded-b-[6px] tw-overflow-hidden tw-mx-2.5 tw-mb-2.5">
              <div className="tw-max-h-[300px] tw-overflow-y-auto">
                {items.map((item, index) => (
                  <NavLink
                    key={index}
                    to={getLocalLink(`/dashboard/suppliers/${item.slug}`)}
                    className="tw-block tw-px-2 tw-py-3 tw-text-sm tw-font-normal tw-text-greyOne hover:tw-bg-greyEight tw-no-underline tw-transition-all tw-duration-200 tw-ease-in-out"
                    onClick={handleResultClick}
                  >
                    {highlightText(item.name, query)}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileSearch;
