import React, { useEffect } from 'react';
import { useSearchParams, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useCompetingCompanies from '../../../../hooks/useCompetingCompanies';
import usePartnerProfileLevelZeroCategories from '../../../../hooks/usePartnerProfileLevelZeroCategories';
import CategoryFilter from './CategoryFilter';
import CompanyCard from './CompanyCard';
import SeeAllLink from './SeeAllLink';

import crossCircle from '../../../../assets/ui/circle-cross-greyFive.svg';

import './CompetingCompanies.scss';

function CompetingCompanies({ profile, partnerId, climateAction, status, selectedCategory: initialCategory }) {
  const [searchParams] = useSearchParams();
  const { isDashboard } = useOutletContext();
  const { t } = useTranslation();

  const { companies, fetchCompanies } = useCompetingCompanies(
    profile.id,
    partnerId,
    status,
    climateAction,
  );

  const {
    categories,
    selectedCategory,
    setSelectedCategory,
  } = usePartnerProfileLevelZeroCategories(profile.id, partnerId, initialCategory);

  useEffect(() => {
    if (selectedCategory) {
      fetchCompanies(selectedCategory.id);
    }
  }, [selectedCategory]);

  const hasSingleCategory = profile.categories_unfiltered?.length === 1;

  return (
    <div className="white-bg mb-4 tw-py-[30px] tw-px-5 tw-border tw-border-solid tw-border-greySix tw-rounded">
      <h1 className="tw-text-greyOne tw-text-base tw-font-moskauGrotesk d-flex align-items-middle justify-content-start mb-4s">
        {t('COMPETING_COMPANIES_TITLE')}
      </h1>

      {categories.length > 0 && (
        <CategoryFilter
          categories={categories}
          filteredCategory={selectedCategory}
          onCategoryChange={setSelectedCategory}
          isFilterDisabled={hasSingleCategory}
        />
      )}

      <div className="row mx-0">
        {companies.length > 0 ? (
          <>
            {companies.slice(0, 8).map((company, index) => (
              <CompanyCard
                key={index}
                company={company}
                isDashboard={isDashboard}
                searchParams={searchParams}
                climateAction={climateAction}
                status={status}
                t={t}
              />
            ))}
            <SeeAllLink
              isDashboard={isDashboard}
              selectedCategory={selectedCategory}
              searchParams={searchParams}
              t={t}
            />
          </>
        ) : (
          <div className="tw-w-full tw-bg-backgroundGrey tw-p-6 tw-text-sm tw-text-greyFive tw-font-moskauGrotesk tw-text-center tw-flex tw-flex-col tw-items-center tw-gap-y-3">
            <img src={crossCircle} alt="no competitor" />
            {t('NO_COMPETITORS')}
          </div>
        )}
      </div>
    </div>
  );
}

export default CompetingCompanies;
