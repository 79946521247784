import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import SbtiDistribution from './SbtiDistribution';
import StatisticsFilter from './Filter/StatisticsFilter';
import useCategories from '../../../hooks/useCategories';

function Statistics() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const { partner } = useOutletContext();
  const { t } = useTranslation();
  const {
    categories,
    selectedCategoryId,
    setSelectedCategoryId,
    getCategoryName,
    removeCategory,
  } = useCategories(partner.id);

  const sortedLevels = categories?.sort(
    (a, b) => (b.level?.[0]?.position || 0) - (a.level?.[0]?.position || 0),
  ) || [];

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-bg-greyEight">
      <div className="tw-w-full tw-h-full tw-py-[42px] tw-px-[50px]">
        <h2 className="tw-text-2xl tw-mb-[30px]">{t('PARTNER_DASHBOARD.STATISTICS.HEADER')}</h2>
        <StatisticsFilter
          levels={sortedLevels}
          selectedCategoryId={selectedCategoryId}
          onCategoryChange={setSelectedCategoryId}
          getCategoryName={getCategoryName}
          onRemoveCategory={removeCategory}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectedStatus={setSelectedStatus}
        />
        <SbtiDistribution
          selectedCategoryId={selectedCategoryId}
          getCategoryName={getCategoryName}
          isOpen={isOpen}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </div>
    </div>
  );
}

export default Statistics;
