import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import getUserLocal from '../../../../utils/getUserLocal';

function Audit({ profile }) {
  const [carbonProviderData, setCarbonProviderData] = useState();
  const [auditorData, setAuditorData] = useState();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profile.id}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const { t } = useTranslation();

  const fetchAuditData = async () => {
    try {
      await get(`/audit?locale=${getUserLocal()}`);
      if (response.ok) {
        setAuditorData(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const fetchCarbonProviderData = async () => {
    try {
      await get('carbon-service-provider');
      if (response.ok) {
        setCarbonProviderData(response.data);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchCarbonProviderData();
    fetchAuditData();
  }, [profile]);

  return (
    carbonProviderData?.id || auditorData ? (
      <div className="tw-p-5 tw-white-bg tw-mb-4 tw-border tw-border-solid tw-border-greySix tw-rounded">
        <div className="tw-text-base tw-font-semibold tw-text-black tw-flex tw-items-middle tw-justify-between tw-mb-3.5">
          <h4 className="tw-text-greyOne tw-text-base">
            {t('AUDIT_AND_CARBON_PROVIDER')}
          </h4>
        </div>
        {auditorData?.name && auditorData?.url ? (
          <div>
            <p className="tw-text-sm tw-text-greyOne">
              {t('CARBON_AUDITOR')}
            </p>
            <p className="tw-text-sm tw-font-semibold tw-text-greyOne tw-mt-1">
              {auditorData && auditorData?.name}
            </p>
            {auditorData && auditorData?.url && (
            <a href={auditorData && auditorData?.url} className="tw-text-sm tw-text-greyFour hover:tw-text-greyThree tw-block tw-underline tw-mt-3.5" target="_blank" rel="noreferrer">
              {t('AUDIT_AND_CONSEIL_TEXT_3')}
            </a>
            )}
          </div>
        ) : null}
        {carbonProviderData?.carbon_service_provider_id ? (
          <div className="tw-flex tw-justify-between tw-mt-3.5">
            <div className="tw-self-center tw-flex tw-flex-col">
              <p className="tw-text-sm tw-text-greyOne">
                { t('Prestataire Carbone')}
              </p>
              <p className="tw-text-sm tw-font-semibold tw-text-greyOne tw-mt-1">
                {carbonProviderData?.carbon_service_provider_name}
              </p>
            </div>
            {carbonProviderData?.carbon_service_provider_id && carbonProviderData.carbon_service_provider_is_partner ? (
              <div className="tw-mr-3 tw-flex tw-items-center">
                {(carbonProviderData.carbon_service_provider_is_published === 0) ? (
                  <img
                    src={carbonProviderData.imageSrc}
                    alt={carbonProviderData.carbon_service_provider_name}
                    className="tw-max-h-12 tw-max-w-12"
                    width={48}
                    height={48}
                  />
                ) : (
                  <a
                    href={`/${getUserLocal()}/carbon-service-provider/${carbonProviderData.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: 'pointer' }}
                  >
                    <img
                      src={carbonProviderData.imageSrc}
                      alt={carbonProviderData.carbon_service_provider_name}
                      className="tw-max-h-12 tw-max-w-12"
                      width={48}
                      height={48}
                    />
                  </a>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
        {carbonProviderData?.carbon_service_provider_is_partner && carbonProviderData.services_provided.length > 0 ? (
          <div className="tw-mt-3.5">
            <div className="tw-text-sm tw-text-greyThree">
              { t('SERVICES_PROVIDED')}
            </div>
            <div className="tw-mt-1 tw-flex tw-itms-center tw-gap-[5px] tw-flex-wrap">
              {carbonProviderData.services_provided.map((prestation) => (
                <div className="tw-text-greyTwo tw-text-xs border tw-border-greySix tw-rounded-[40px] tw-bg-backgroundCleanBlue tw-px-4 tw-py-1.5">{prestation.name}</div>
              ))}
            </div>
          </div>
        ) : ('')}
        {carbonProviderData && carbonProviderData.carbon_service_provider_is_partner ? (
          <div className="tw-mt-3.5">
            <a href={`/${getUserLocal()}/carbon-service-provider/${carbonProviderData.slug}`} target="_blank" rel="noopener noreferrer" className="tw-text-sm tw-text-greyFour tw-underline">
              {t('DISCOVER_CARBON_PROVIDER_PAGE')}
            </a>
          </div>
        ) : ('')}
      </div>
    ) : null
  );
}

export default Audit;
