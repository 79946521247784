import React, { useState } from 'react';
import FilterDropdown from './FilterDropdown';
import SelectedCategories from './SelectedCategories';

function StatisticsFilter({
  levels,
  selectedCategoryId,
  onCategoryChange,
  getCategoryName,
  onRemoveCategory,
  isOpen,
  setIsOpen,
  setSelectedStatus,
}) {
  const [navigationPath, setNavigationPath] = useState([]);
  return (
    <div className="tw-mb-[18px]">
      <FilterDropdown
        levels={levels}
        selectedCategoryId={selectedCategoryId}
        onChange={onCategoryChange}
        navigationPath={navigationPath}
        setNavigationPath={setNavigationPath}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setSelectedStatus={setSelectedStatus}
      />
      {selectedCategoryId && (
        <SelectedCategories
          categoryId={selectedCategoryId}
          getCategoryName={getCategoryName}
          onRemove={onRemoveCategory}
          setNavigationPath={setNavigationPath}
        />
      )}
    </div>
  );
}

export default StatisticsFilter;
