import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getHexColorWithOpacity from '../../../utils/getHexColorWithOpacity';

function Tooltip({ id, value, count }) {
  const { t } = useTranslation();
  const supplierCount = count || 0;
  const supplierText = `${supplierCount} ${t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.CHART.SUPPLIERS')}${supplierCount > 1 ? 's' : ''}`;

  return (
    <div
      style={{
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 12,
        paddingRight: 12,
        color: '#00051B',
        background: 'white',
        boxShadow: '0px 4px 10px 0px #0000001F',
        position: 'relative',
        transform: 'translate(60%, 80%)',
        borderRadius: '6px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 0,
          height: 0,
          position: 'absolute',
          left: -9,
          top: '50%',
          transform: 'translateY(-50%)',
          borderTop: '10px solid transparent',
          borderBottom: '10px solid transparent',
          borderRight: '10px solid white',
        }}
      />
      <div className="tw-font-moskauGrotesk tw-text-xs tw-text-greyOne tw-font-semibold tw-inline-block tw-whitespace-nowrap tw-text-center">
        {id}: {value}%
      </div>
      <div className="tw-font-moskauGrotesk tw-text-xs tw-text-greyOne tw-inline-block tw-whitespace-nowrap mt-[2px]">
        {supplierText}
      </div>
    </div>
  );
}

function MainChart({
  sbtiPercentageByStatus,
  profileCountsBySbtiStatus,
  colorsObjects,
  onStatusSelect,
  selectedCategoryId,
  selectedStatus,
  isOpen,
}) {
  const [data, setData] = useState([]);
  const [colors, setColors] = useState({});
  const [chartKeys, setChartKeys] = useState([]);
  const [prevIsOpen, setPrevIsOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setPrevIsOpen(isOpen);
  }, [isOpen]);

  const customTooltip = (props) => (
    <Tooltip {...props} count={profileCountsBySbtiStatus?.[props.id]} />
  );

  const getColor = (bar) => `url(#gradient-${bar.id.replace(/\s+/g, '-')})`;

  const createGradientDefs = () => {
    if (!colors) return [];

    return Object.entries(colors).map(([id, color]) => ({
      id: `gradient-${id.replace(/\s+/g, '-')}`,
      type: 'linearGradient',
      colors: [
        {
          offset: 0,
          color: selectedStatus === null || selectedStatus === id
            ? getHexColorWithOpacity(color, 1)
            : getHexColorWithOpacity(color, 0.2),
        },
        {
          offset: 100,
          color: selectedStatus === null || selectedStatus === id
            ? getHexColorWithOpacity(color, 0.75)
            : getHexColorWithOpacity(color, 0.15),
        },
      ],
    }));
  };

  const theme = {
    grid: {
      line: {
        stroke: '#DFE4EA',
        strokeWidth: 1,
        strokeDasharray: '4 4',
      },
    },
  };

  useEffect(() => {
    if (selectedCategoryId === null) {
      onStatusSelect(null);
    }
  }, [selectedCategoryId]);

  useEffect(() => {
    const data = [
      {
        Category: 'Category',
        ...(sbtiPercentageByStatus || {}),
      },
    ];

    const newChartKeys = Object.keys(sbtiPercentageByStatus || {}).filter(
      (key) => typeof sbtiPercentageByStatus[key] === 'number',
    );

    setColors(colorsObjects);
    setData(data);
    setChartKeys(newChartKeys);
  }, [sbtiPercentageByStatus]);

  const handleBarClick = (bar) => {
    if (!bar?.id) return;
    const newSelectedStatus = selectedStatus === bar.id ? null : bar.id;
    onStatusSelect(newSelectedStatus);
    const supliersListDiv = document.getElementById('suppliers-list');
    if (supliersListDiv) {
      supliersListDiv.scrollTop = 0;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.chart-container')
        && !event.target.closest('.chart-legend')
        && !isOpen
        && !prevIsOpen
      ) {
        onStatusSelect(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onStatusSelect, prevIsOpen, isOpen]);

  return (
    <div className="chart-container tw-w-full tw-h-full">
      {data && colors ? (
        <ResponsiveBar
          theme={theme}
          tooltip={customTooltip}
          data={data}
          keys={chartKeys}
          valueFormat={(value) => `${value}%`}
          maxValue={100}
          indexBy="Category"
          margin={{ top: 15, right: 24, bottom: 15, left: 40 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={getColor}
          defs={createGradientDefs()}
          fill={Object.keys(colors).map((id) => ({
            match: { id },
            id: `gradient-${id.replace(/\s+/g, '-')}`,
          }))}
          enableLabel={false}
          onClick={handleBarClick}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          animate
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            legendOffset: -40,
            format: (value) => `${value}%`,
            tickValues: [0, 20, 40, 60, 80, 100],
            renderTick: ({ value, x, y }) => (
              <g transform={`translate(${x},${y})`}>
                <text
                  style={{
                    fill: '#9398AC',
                    fontSize: '12px',
                  }}
                  textAnchor="end"
                  dominantBaseline="central"
                  x={-10}
                >
                  {`${value}%`}
                </text>
              </g>
            ),
          }}
          gridYValues={[0, 20, 40, 60, 80, 100]}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          role="application"
          ariaLabel={t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.CHART.ARIA_LABEL')}
          barAriaLabel={(e) => `${e.id}: ${e.formattedValue} ${t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.CHART.BAR_ARIA_LABEL_A')} (${
            profileCountsBySbtiStatus?.[e.id] || 0
          } ${t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.CHART.BAR_ARIA_LABEL_B')})`}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default MainChart;
