import React from 'react';
import { useTranslation } from 'react-i18next';

function BarChartLegend({ legend, selectedStatus, onStatusSelect }) {
  const { t } = useTranslation();

  const noLegendObject = {
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.VERY_STRONG')]: '#0EBF76',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.STRONG')]: '#6CB14A',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.INTERMEDIATE')]: '#F8D55B',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.INSUFFICIENT')]: '#F68F57',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.VERY_INSUFFICIENT')]: '#E63756',
    [t('PARTNER_DASHBOARD.STATISTICS.SBTI_DISTRIBUTION.LEGEND.NO_REPORTING')]: '#B41A35',
  };

  const legendToUse = legend || noLegendObject;

  const getOpacity = (status) => {
    if (!selectedStatus) return 1;
    return status === selectedStatus ? 1 : 0.2;
  };

  const handleSelection = (status) => {
    onStatusSelect(selectedStatus === status ? null : status);
    const supliersListDiv = document.getElementById('suppliers-list');
    if (supliersListDiv) {
      supliersListDiv.scrollTop = 0;
    }
  };

  return (
    <div className="tw-w-full tw-flex tw-flex-wrap tw-items-center tw-gap-x-[15px] tw-gap-y-2 tw-mt-5">
      {Object.entries(legendToUse).map((item, index) => {
        const opacity = getOpacity(item[0]);

        return (
          <button
            key={index}
            type="button"
            tabIndex={0}
            onClick={() => handleSelection(item[0])}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleSelection(item[0]);
              }
            }}
            className="chart-legend tw-flex tw-items-center tw-cursor-pointer hover:tw-opacity-80 tw-border-none tw-bg-transparent"
            style={{ opacity }}
          >
            <div
              className="tw-w-[14px] tw-h-[14px] tw-rounded-full tw-p-[3px]"
              style={{ backgroundColor: `${item[1]}10` }}
            >
              <div
                className="tw-w-full tw-h-full tw-rounded-full"
                style={{ backgroundColor: item[1] }}
              />
            </div>
            <span className="tw-text-xs text-greyOne tw-font-moskauGrotesk tw-ml-1">
              {item[0]}
            </span>
          </button>
        );
      })}
    </div>
  );
}

export default BarChartLegend;
