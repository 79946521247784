import React from 'react';
import { useTranslation } from 'react-i18next';

import Widget from './Widget';
import NoCimateActions from './NoClimateActions';

function DetailsMobile({ scope, unitText, scopeColor, getColorByScore, getClimateAlignementInfos, getHexColorWithOpacity, dataUsable, dataNotComparable, flag }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="tw-mt-6 tw-flex tw-justify-between">
        <div className="tw-flex tw-w-1/2 tw-items-center tw-text-xs tw-text-greyFour">
          {t('CLIMATE_ACTIONS.ACTUAL_REDUCTION')}
        </div>
        {scope?.degreeScore >= 2 && !dataUsable && !dataNotComparable && (flag ? scope?.degreeScore >= 3 : true) ? (
          <div className="tw-text-center tw-w-1/2">
            <div className="tw-text-lg tw-text-greyOne tw-font-moskauGrotesk tw-font-semibold">
              {scope?.percentDelta > 0 ? '+' : ''}{scope?.percentDelta}%
            </div>
            <div className="tw-text-xs tw-text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
            <div className="tw-text-xs tw-text-greyTwo">
              {t('CLIMATE_ACTIONS.BETWEEN')} {scope?.startYear} {t('CLIMATE_ACTIONS.AND')} {scope?.endYear}
            </div>
          </div>
        ) : ('')}
      </div>
      <div className="tw-mt-6 tw-flex tw-justify-between">
        <div className="tw-flex tw-w-1/2 tw-items-center tw-text-xs tw-text-greyFour">
          {t('CLIMATE_ACTIONS.REDUCTION_SPEED')}
        </div>
        {scope?.degreeScore >= 2 && !dataUsable && !dataNotComparable && (flag ? scope?.degreeScore >= 3 : true) ? (
          <div className="tw-w-1/2 tw-text-center tw-text-greyOne tw-text-lg tw-flex tw-flex-col tw-justify-end">
            <div className="tw-font-moskauGrotesk tw-font-semibold">
              {(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2) > 0 ? '+' : ''}{(scope && scope.percentDelta && scope?.startYear && scope.endYear) && (scope.percentDelta / (scope.endYear - scope.startYear)).toFixed(2)}% / {t('CLIMATE_ACTIONS.YEAR')}
            </div>
            <div className="tw-text-xs tw-text-greyTwo">
              {t(unitText[scope?.reduceType])}
            </div>
          </div>
        ) : ('')}
      </div>
      <div className="tw-mt-6 tw-flex tw-justify-between">
        <div className="tw-flex tw-w-1/2 tw-items-center tw-text-xs tw-text-greyFour">
          {t('CLIMATE_ACTIONS.CLIMATE_ALIGNEMENT')}
        </div>
        <div className="tw-w-1/2">
          {(dataUsable || dataNotComparable) ? (
            <NoCimateActions dataUsable={dataUsable} dataNotComparable={dataNotComparable} />
          ) : (
            <Widget
              getColorByScore={getColorByScore}
              getClimateAlignementInfos={getClimateAlignementInfos}
              getHexColorWithOpacity={getHexColorWithOpacity}
              degreeScore={scope?.degreeScore}
              color={scopeColor}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailsMobile;
