import React, { useState } from 'react';
import { useLoaderData, Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { SocketProvider } from '../../context/SocketContext';

import tailwindConfig from '../../tailwind.config';

import Sidebar from './Sidebar/Sidebar';
import verifyAuthorization from '../../utils/verifyAuthorization';
import { TableLoadingProvider } from '../../context/TableLoadingProvider';
import ProfileSearch from './ProfileSearch/ProfileSearch';

function Dashboard() {
  const { partner, session, features, isDashboard } = useLoaderData();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${tailwindConfig.theme.extend.screens.md})`,
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(!!isDesktop);

  const hasWriteAccess = verifyAuthorization(session, ['ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']);

  return (
    <div className="tw-h-screen tw-flex tw-bg-whiteGrey tw-overflow-hidden">
      <SocketProvider session={session}>
        <TableLoadingProvider>
          <Sidebar
            session={session}
            logo={partner.logo_banner ?? partner.logo_header}
            partner={partner}
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <div className={`tw-h-screen tw-transition-all tw-duration-300 ${
            isSidebarOpen ? 'tw-w-[calc(100%_-_300px)]' : 'tw-w-[calc(100%_-_80px)]'
          } tw-flex tw-flex-col tw-overflow-hidden`}
          >
            <div className="tw-w-full tw-h-[60px] tw-flex tw-items-center tw-justify-center tw-bg-white tw-border tw-border-x-0 tw-border-t-0 tw-border-b tw-border-solid tw-border-b-greySeven">
              <div className={`tw-transition-all tw-duration-300 ${
                isSidebarOpen ? '-tw-ml-[285px]' : '-tw-ml-[75px]'
              }`}
              >
                <ProfileSearch partner={partner} />
              </div>
            </div>
            <div className="tw-flex-1 tw-overflow-auto">
              <Outlet context={{ partner, session, features, hasWriteAccess, isDashboard }} />
            </div>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </TableLoadingProvider>
      </SocketProvider>
    </div>
  );
}

export default Dashboard;
